module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"zfr4mzp"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/BelleMeadeEndo_Mark_RGB.png","name":"Belle Meade Endodontics","short_name":"Belle Meade Endo","start_url":"/","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"548863c44aa6f2d52e2190e8fc619497"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
